/* eslint-disable no-undef */
import React from "react"
import data from "@components/pageHome/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageHome/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import Medios from "@components/sharedComponents/medios/medios"
import Support from "@components/pageHome/support/support"
import AccordionRight from "@components/sharedComponents/accordionRight/accordionRight"
import Products from "@components/pageHome/products/products"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import TitleUnderline from "@components/pageHome/titleUnderline/titleUnderlineAccordionRight"
import TitleUnderlineTestimony from "@components/pageHome/titleUnderline/titleUnderlineTestimony"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import audifonos from "@components/sharedComponents/testimonies/images/iconos/audifonos.svg"
import salud from "@components/sharedComponents/testimonies/images/iconos/salud.svg"
import ecommerce from "@components/sharedComponents/testimonies/images/iconos/ecommerce.svg"
import concesionarias from "@components/sharedComponents/testimonies/images/iconos/concesionarias.svg"
import Operations from "@components/pageHome/operations/operations"
import SliderCustomersLogos from "@components/sharedComponents/sliderCustomersLogos/sliderCustomersLogos"
import {TitleUnderlineOperationsHome} from "@components/pageHome/operations/titleUnderline"

const TemplatePageHome = ({ location }) => {
  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  const titleAccordionRight = <p>Beex potencia tu <TitleUnderline underline="Contact" /> <TitleUnderline underline="Center" /> y la comunicación con tus usuarios</p>
  const titleTestimony = <p>Conoce lo que dicen <TitleUnderlineTestimony underline="nuestros clientes" /> y cómo ayudamos a diferentes industrias</p>

  const iconsSilder = i => {
    switch (i) {
      case 0:
        return finanzas
      case 1:
        return audifonos
      case 2:
        return salud
      case 3:
        return ecommerce
      case 4:
        return concesionarias
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Finanzas"
      case 1:
        return "Contact Center & BPO"
      case 2:
        return "Salud"
      case 3:
        return "Ecommerce"
      case 4:
        return "Concesionarias"
      default:
        return "Conoce otros casos de éxito"
    }
  }
  const titleOperations = <p className="container-operations-title">
    Automatiza tu servicio al cliente, marketing y ventas con nuestras{" "}
    <TitleUnderlineOperationsHome underline="soluciones" />
  </p>
  return (
    <div className="fnd">
      <section>
        <BannerCookies />
          <Header
            path="/"
            location={location}
            windowsWidth={windowsWidth}
          />
        <div className="container">
          <Start data={data.start} location={location} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title=" Únete a las empresas que confían en nosotros" />
          <AccordionRight data={data.accordion} title={titleAccordionRight} />
          <SliderCustomersLogos />
          <div className="container-slider-customer-space"/>
          <BannerCTA
            title="Atiende mejor, vende más y fideliza a tus clientes con un software escalable"
            text="Agenda una reunión con uno de nuestros expertos y mira nuestras soluciones en acción."
            link="/hablemos/"
            textButton="Acceder a Demo"
          />
          <Operations data={data.modules} location={location} title={titleOperations} />
          <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />
          <Products data={data.products} location={location} />
          <Support location={location} />
          <Medios data={data.medios} />
          <ArticleBlog data={data.articles} home={true} />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageHome
